import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import {
  CallComposite,
  useTeamsCallAdapter,
  fromFlatCommunicationIdentifier,
} from "@azure/communication-react";
import React, { useCallback, useMemo, useRef } from "react";
import { useFrameCommunication } from "../providers/FrameCommunication";

const leaveCall = async (adapter) => {
  const { sendExitEvent } = useFrameCommunication();
  console.debug("Frame hard shut down of Teams call");
  sendExitEvent();
  await adapter.leaveCall().catch((e) => {
    console.error("Failed to leave call", e);
  });
};

const TeamsUserCallContainer = (props) => {
  const callStarted = useRef(false);
  const { sendExitEvent, sendCallStartedEvent } = useFrameCommunication();

  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(props.token); // <-- This props.token would be your Teams access token
    } catch {
      console.error("Failed to construct token credential");
      return undefined;
    }
  }, [props.token]);

  // For multiple Azure Communication apps joining the same Teams meeting,
  // you will need to provide a displayName for other participants joining using Teams Identity,
  // otherwise "Unnamed Participant" would be shown as their default names.
  const onFetchProfile = useCallback(async (userId, defaultProfile) => {
    if (defaultProfile && defaultProfile.displayName) {
      return defaultProfile;
    }
    // You can fetch the display name from GraphAPI or your backend service using userId
    return { displayName: props.displayName };
  }, []);

  const options = useMemo(
    () => ({
      onFetchProfile,
    }),
    [onFetchProfile]
  );

  const teamsCallAdapterArgs = useMemo(
    () => ({
      userId: props.userId,
      credential,
      locator: {
        meetingLink: props.meetingUrl,
      },
      options,
    }),
    [props.userId, credential]
  );

  const adapter = useTeamsCallAdapter(
    teamsCallAdapterArgs,
    undefined,
    leaveCall
  );

  if (!props.meetingUrl) {
    return <>Teams meeting link is not provided.</>;
  }

  if (adapter) {
    // subscribe to call end (as oposed to leaving a call which is a hard shut down)
    adapter.on("callEnded", (e) => {
      callStarted.current = false;
      console.debug("User clicked to leave the Teams call", e);
      sendExitEvent();
    });
    adapter.onStateChange((callState) => {
      if (
        !callStarted.current &&
        callState.page === "call" &&
        callState.call &&
        callState.call.kind === "TeamsCall" &&
        callState.call.role === "Organizer" &&
        callState.call.state === "Connected" &&
        callState.call.direction === "Outgoing"
      ) {
        callStarted.current = true;
        console.debug("$$$$$$$$  Call started");
        sendCallStartedEvent();
      }
    });

    return (
      <div style={{ height: "100vh", width: "100vw" }}>
        <CallComposite
          adapter={adapter}
          formFactor={props.formFactor}
          fluentTheme={props.fluentTheme}
          callInvitationUrl={props.callInvitationURL}
          locale={props.locale}
          options={{
            callControls: {
              endCallButton: true,
              screenShareButton: false,
              displayType: "compact",
            },
          }}
        />
      </div>
    );
  }
  if (credential === undefined) {
    return <>Failed to construct credential. Provided token is malformed.</>;
  }
  return <>Initializing...</>;
};

const TeamsUserCall = ({ displayName, userId, token, meetingLink }) => {
  return (
    <TeamsUserCallContainer
      displayName={displayName}
      userId={fromFlatCommunicationIdentifier(userId)}
      token={token}
      meetingUrl={meetingLink}
      formFactor="desktop"
    />
  );
};

export default TeamsUserCall;

import React from "react";
import "../styles/App.css";
import { FrameCommunicationProvider } from "../providers/FrameCommunication";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import TeamsUserCall from "./TeamsUserCall";

const searchParams = new URLSearchParams(window.location.search);

export default function WebApp() {
  const meetingUrl = searchParams.get("link");
  const userId = searchParams.get("uid");
  const token = searchParams.get("t");
  const userName = searchParams.get("name");

  return (
    <FrameCommunicationProvider>
      <div className="App">
        {meetingUrl && userId && token ? (
          <TeamsUserCall
            meetingLink={meetingUrl}
            userId={userId}
            token={token}
            displayName={userName}
          />
        ) : (
          <Spinner size={SpinnerSize.large} />
        )}
      </div>
    </FrameCommunicationProvider>
  );
}

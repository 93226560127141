import { useMemo } from 'react';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import {
  CallComposite,
  useAzureCommunicationCallAdapter,
  fromFlatCommunicationIdentifier
} from '@azure/communication-react';
import { useFrameCommunication } from '../providers/FrameCommunication';



// const isTeamsMeetingLink = (link: string): boolean => link.startsWith('https://teams.microsoft.com/l/meetup-join');
// const isGroupID = (id: string): boolean => validateUUID(id);
// const isRoomID = (id: string): boolean => {
//   const num = Number(id);

//   if (Number.isInteger(num) && num > 0) {
//     return true;
//   }

//   return false;
// };

// const createCallAdapterLocator = (locator: string): CallAdapterLocator | undefined => {
//   if (isTeamsMeetingLink(locator)) {
//     return { meetingLink: locator };
//   } else if (isGroupID(locator)) {
//     return { groupId: locator };
//   } else if (isRoomID(locator)) {
//     return { roomId: locator };
//   }
//   return undefined;
// };

const TeamsCallContainer = (props) => {
  const { sendExitEvent } = useFrameCommunication();
  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(props.token);
    } catch {
      console.error('Failed to construct token credential');
      return undefined;
    }
  }, [props.token]);

  const locator = useMemo(() => {return { meetingLink: props.locator }}, [props.locator]);
 
  const adapter = useAzureCommunicationCallAdapter(
    {
      userId: props.userId,
      displayName: props.displayName,
      credential,
      locator
    },
    undefined,
    leaveCall
  );

  if (!locator) {
    return <>Provided call locator '{props.locator}' is not recognized.</>;
  }

  if (adapter) {
    // adapter.on("callEnded", () => {
    //   console.debug("callEnded");
    // });
    adapter.on("participantsLeft", (event) => {
      console.debug("participantsLeft", event);
      if (event.role === "Organizer") {
        sendExitEvent();
      }
    });

    return (
        <CallComposite
          adapter={adapter}
          formFactor={props.formFactor}
          fluentTheme={props.fluentTheme}
          callInvitationUrl={props.callInvitationURL}
          locale={props.locale}
          options={{
            callControls: {
              endCallButton: false,
              screenShareButton: false,
              displayType: "compact",
            },
          }}
        />
    );
  }
  if (credential === undefined) {
    return <>Failed to construct credential. Provided token is malformed.</>;
  }
  return <>Initializing...</>;
};

const leaveCall = async (adapter) => {
  await adapter.leaveCall().catch((e) => {
    console.error('Failed to leave call', e);
  });
};


const TeamsGuestCall = ({displayName, userId, token, meetingLink}) => {
    return (
        <div style={{ height: '100vh', width: '100vw' }}>
          <TeamsCallContainer 
            userId={fromFlatCommunicationIdentifier(userId)} 
            token={token} 
            locator={meetingLink}
            displayName={displayName}
            formFactor="mobile"
            />
        </div>
      );
}

export default TeamsGuestCall;
import TeamsUserCall from "./TeamsUserCall";
import TeamsGuestCall from "./TeamsGuestCall";
const searchParams = new URLSearchParams(window.location.search);

export default function Custom() {
  return (
    <div className="App">
      {searchParams.get("teams") ? (
        <TeamsUserCall
          userId={searchParams.get("id")}
          meetingLink={searchParams.get("link")}
          token={searchParams.get("token")}
        />) :
        searchParams.get("id") ? (
        <TeamsGuestCall
          userId={searchParams.get("id")}
          displayName="guest"
          meetingLink={searchParams.get("link")}
          token={searchParams.get("token")}
        />
      )  : null}
    </div>
  );
}

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WebApp from "./components/WebApp";
import Widget from "./components/Widget";
import Custom from "./components/Custom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import "./styles/App.css";

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  const Home = () => (
    <div className="App">
      <img src="./logo.png" width="300px" height="300px" style={{marginTop: "30%"}}/>
    </div>
  );
  const TeamsUserApp = () => (
    <MsalProvider instance={msalInstance}>
      <WebApp />
    </MsalProvider>
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/webapp" element={<TeamsUserApp />} />
        <Route path="/widget" element={<Widget />} />
        <Route path="/custom" element={<Custom />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import { useEffect } from "react";
import EventEmitter from "eventemitter3";

const inAppEmitter = new EventEmitter();

export const EventNames = {
  // cross tab sync
  onExternalMessage: "message", // messages coming from outside the widget

  // window events
  onLeaderSelected: "onLeaderSelected",
  onWindowVisibilityChange: "visibilitychange",
  onWindowFocus: "focus",

  // online meeting
  onPlayerLoaded: "onPlayerLoaded",
  onPlayerTeamsUserRequest: "onPlayerTeamsUserRequest",
  onPlayerTeamsUserResponse: "onPlayerTeamsUserResponse",
  onPlayerMeetingEnded: "onPlayerMeetingEnded",
  onPlayerMeetingOrganizerJoined: "onPlayerMeetingOrganizerJoined"
};

export const AppEmitter = {
  on: (event, fn) => inAppEmitter.on(event, fn),
  once: (event, fn) => inAppEmitter.once(event, fn),
  off: (event, fn) => inAppEmitter.off(event, fn),
  emit: (event, payload) => {
    inAppEmitter.emit(event, payload);
  },
};

Object.freeze(AppEmitter);

export const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useDocumentEvent = (event, callback) => {
  useEffect(() => {
    document.addEventListener(event, callback);
    return () => document.removeEventListener(event, callback);
  }, [event, callback]);
};


export const useStatelessAppEvent = (event, callback, isOnce) => {
  useEffect(() => {
    if (isOnce) {
      AppEmitter.once(event, callback);
    } else {
      AppEmitter.on(event, callback);
    }
    return () => AppEmitter.off(event, callback);
  }, [event, isOnce, callback]);
};



import TeamsGuestCall from "./TeamsGuestCall";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { FrameCommunicationProvider } from "../providers/FrameCommunication";
const searchParams = new URLSearchParams(window.location.search);

export default function Widget() {
  const meetingLink = searchParams.get("link");
  const id = searchParams.get("id");
  const token = searchParams.get("t");
  const name = searchParams.get("name");

  return (
    <FrameCommunicationProvider>
      <div className="App">
        {meetingLink && id && token ? (
          <TeamsGuestCall
            userId={id}
            displayName={name}
            meetingLink={meetingLink}
            token={token}
          />
        ) : (
          <Spinner size={SpinnerSize.large} />
        )}
      </div>
    </FrameCommunicationProvider>
  );
}
